import Store from '../Asset/store.png'
import paper from '../Asset/paper.png';
import Agr from '../Asset/akbaiLogo.png'
import international from '../Asset/international.png';
import chemical from '../Asset/chemical.png'
import School from '../Asset/school.png';

export const CompanyData = [
    {
        id:1,
        image:Store,
        text : "Akbari Stores was established in 1948 by Sheikh Sirajud Din Ahmad (late) and his son Sheikh Fazal Elahi Akbar (late). It is the founding stone of Akbari Group of Companies.",
        link : "http://www.akbari.com.pk/index.html",
        buttonTitle : " Akbari Stores"
    },
    {
        id:2,
        image:chemical,
        text : "Akbari Chemical Industries (Pvt) Ltd. is a renowned name in Pakistan's chemical manufacturing market and since its inception in 1979, has been offering quality products.",
        link : "https://aci.com.pk/",
        buttonTitle : "Akbari Chemical Industries (Pvt) Ltd"
    },
    {
        id:3,
        image:paper,
        text : "Akbari Paper Store was established in 1990 by Sheikh Muhammad Ahmed Raza. It is an integral part of Akbari Group. Its establishment brought diversification to the business",
        link : "https://aci.com.pk/paper_store.php",
        buttonTitle : "Akbari Paper Store"
    }, {
        id:4,
        image:international,
        text : "AIC is an international trading and indenting firm. It is part of the Akbari Group of Companies (Est. 1948) and is a reliable source for importing industrial.",
        link : "http://www.aico.com.pk/",
        buttonTitle : "Akbari International Corporation"
    }
    , {
        id:5,
        image:Agr,
        text : "Our most recent diversification came in 2020 when we established Akbari Agrichem (Pvt) Ltd; a project dedicated solely for agricultural industry. Keeping in mind that our cultivable land is alkaline, our focus is to produce acidic fertilizers. We’re manufacturing Single Super Phosphate and Sulphate of Potash at our imported manufacturing plants to counter our soil’s alkalinity.",
        link : "/",
        logotext:"Akbari Agrichem (Pvt) Ltd",
        buttonTitle : "Akbari Agrichem (Pvt) Ltd"
    }
    , {
        id:6,
        image:School,
        text : "Siraj House Society (trust) was established in 1979 by Sheikh Fazal Elahi Akbar (late), with an objective to serve the society in particular and the nation in general.",
        link : "http://www.akbarischool.edu.pk/",
        buttonTitle : " Akbari School"
    }
]