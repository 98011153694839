

export const ProductListData = [
  {
    id: 1,
    title: "HYDROCHLORIC ACID (HCl)",
    description:
      "Hydrochloric Acid also known as muriatic acid is an aqueous solution of hydrogen chloride with chemical formula HCl. It is a colorless solution with a distinctive pungent smell. Without the HCl being dissolved in water, HCl is naturally a poisonous gas at room temperature. Hydrochloric acid is considered to be a strong acid because it is among the readiest compounds to deprotonate and donate hydrogen ions in solutions on. HCl is corrosive to the eyes, skin and mucous membranes.  ",
    subtitle1: "Packaging and Shipping",
    subdescriptions:
      "ACI’s Hydrochloric Acid can be delivered in 10/35 MT rubber lined tankers and in 30KG HDPE jars. ",
    subtitle2: "Storage  Handling & Safety Measures",
    subdescriptions2:
      "The chemical has met Safer Choice Criteria for its functional ingredient class, but has some hazard profile issues. In other words, a chemical with this code is not associated with a low hazard concern for environmental endpoints and human health. Some useful recommendations are as follows:",
    list2: [
      {
        id: 1,
        listData:
          "Avoid contact with skin and eyes. Avoid inhalation of vapor or mist.",
      },
      {
        id: 2,
        listData:
          " Always use inside a properly functioning chemical fume hood.",
      },
      {
        id: 3,
        listData:
          "Keep away from sources of ignition. Avoid heat, sparks, shock or friction when handling.",
      },
      { id: 4, listData: "Keep containers closed when not in use." },
    ],
    subheading3:"Storage ",
    listPassage:
      "The following safety measures should be taken when dealing with Formic acid:",
    list: [
      {
        id: 1,
        listData: "HCl should be stored in a cool, dry, well- ventilated area away from sources of moisture.",
      },
      {
        id: 2,
        listData:
          "HCl should be stored in FRP, rubber-lined steel, and polypropylene tanks.",
      },
      {
        id: 3,
        listData:
          "Secondary containment measures are required for bulk quantities of hydrochloric acid due to its chemical hazards. Engineering standards must contain 110% of total HCL tank system volume in the event of a release.",
      },
      {
        id: 4,
        listData:
          "HCl should be stored away from heat sources, direct sunlight, and incompa ble materials due to its ability to convert into poisonous gas.",
      },

    ],
    note: "NOC from ministry of Narcotics Control is mandatory for HCl purchase. For further information please contact the ministry directly.",
    tableheading: "Technical specifica ons",
    ProdList: [
      { name: "Concentration ", score: "32-34%" },
      { name: "Chemical Formula ", score: "HCl" },
      { name: "Molecular Mass ", score: "36.46 g/mol" },
      { name: "Appearance", score: "Light Yellowish Colorless, transparent liquid" },
      { name: "Specific Gravity @ 25c° ", score: "1.164-1.174 " },
      { name: "Molarity", score: "10.9 " },
      { name: "pH ", score: "3.01 " },
      { name: "Boiling Point", score: "71" },
      { name: "Melting Point ", score: "-36 " },
      { name: "Iron (Fe) ", score: "max 0.005% " },
      { name: "Chlorine (Cl2) ", score: "max 5 ppm " },
      { name: "Mercury (Hg) ", score: "Nil " },
    ],
  },


  {
    id: 2,
    title: "Sulphate of Potash",
    description:
      <p>Sulphate of potash (SOP) also called Potassium Sulphate is an inorganic compound with formula K<sub>2</sub>SO<sub>4</sub> is a white water-soluble powder. It is commonly used in fertilizers, providing both potassium and sulfur. SOP is doubly essential not just because of the potassium, but also because it provides plants with sulphur the first-micro nutrient. Moreover, AAC's SOP doesn't contain Chloride and therefore is beneficial to all crops and plants. SOP has zero salinity index which means eventhough it's a salt, it doesn't build up the soil's salinity. Where soils are saline and where irrigation water may have high chloride levels. SOP is the preferred form of potassium to use. SOP can be added with the seed or near the seedling, ensuring maximum root access to potassium. Plants deficient in potassium can appear scorched on the leaves, be stunted in growth, and have less water circulation resulting in heat and drought susceptibility. They will also have poor resistance to pests and weak roots.</p>,
    subtitle1: "Available Packaging",
    subdescriptions:
      "AAC's water-soluble Potassium Sulphate is packed 10-6 cm3/mol and supplied in 50 and 25 kg poly propylene(PP)",
    subtitle2: "Storage and Handling",
    subdescriptions2:
      "Potassium sulphate should be stored in a cool dry place with adequate ventilation to keep airborne concentrations to a minimum. Exposure to eyes, skin and clothing should be avoided.",
    tableheading: "Technical Specifications",
    ProdList: [
      { name: "Chemical Formula", score: <p>K<sub>2</sub>SO<sub>4</sub></p> },
      { name: "Molar mass", score: "174.259 g/mol" },
      { name: "Appearance", score: "White powder" },
      { name: "Magnetic Susceptibility (x)", score: "−67.0·10−6 cm3/mol" },
      { name: "Refractive index (nD)", score: "1.495" },
      { name: "Odor", score: "odorless" },
      { name: <p>Total Potassium as (K<sub>2</sub>O)</p>, score: "min 50%" },
      { name: "Sulphur (S)", score: "min 17%" },
      { name: "Chloride ions", score: "max 2%" },
      { name: "Moisture", score: "<0.1" },
      { name: "PH", score: "max 4%" },
      { name: "Density", score: "2.66 g/cm3[1]" },
      { name: "Melting point", score: "1,069[2] °C (1,956 \°F; 1,342 K)" },
      { name: "Boiling point", score: "1,689 °C (3,072 \°F; 1,962 K)" },
      { name: "Solubility in water", score: "111 g/L (20°C), 120 g/L (25 °C)" },
    ],
  },



  {
    id: 3,
    title: "Single Super Phosphate SSP",
    description:
      "Single Super Phosphate is a fertilizer that is produced by Akbari Agrichem at its new site. Its main application is in the agricultural sector. Since, Pakistan is an agrarian economy, the principal use of SSP is evident and it is a highly demanded fertilizer mostly used at the time of preparation of the land. Its water-soluble phosphate content can be easily consumed by plants and for better yield, its effectiveness remains unquestioned.",
    subtitle1: "Available Packaging",
    subdescriptions:
      "ACI's SSP is available in bulk 50 Kg Packing (PP bags with PE liner) and 2 different varieties as listed above.",
    tableheading:
      "Technical Specifications ",
    ProdList: [
      { name: <span>P<sub>2</sub>O<sub>5</sub></span>, score: "14%-18%" },
      { name: <span>CaSO<sub>4</sub></span>, score: "46%" },
      { name: "Moisture", score: "05% max" },
      { name: "Phosphoric Acid", score: "05% max" },
      { name: "Colour", score: "Grey" },
      { name: "Granule Size", score: "2mm-6mm" }
      // { name: "Bicarbonate, [HCO3 - ]"	, score:'None'}
    ],

    
  },

  {
    id: 4,
    title: "SULPHURIC ACID",
    description:
      "Sulphuric Acid is a strong dibasic acid. In addition, it is also a strong oxidizing and dehydrating agent. It is one of the most widely employed chemicals and enters into many industries, though infrequently appearing in the finished material. Its widespread usage lead Justus, Baron von Liebig, to make the following statement: “The commercial prosperity of a nation can be measured by the amount of sulphuric acid it consumes.",
    subtitle1: "Available Packaging",
    subdescriptions:
      "ACI’s sulphuric acid is available in 25 or 50 kg plastic jars, or in bulk (10 metric ton tanker shipment) as per the client’s requirement.",
    subtitle2: "Storage, Handling and Safety Measures",
    subdescriptions2:
      <p>When not in use, Sulphuric Acid containers should tightly be closed. It should be stored in a cool, dry, well-ventilated area away from incompatible, combustible and alkaline substances. Avoid breathing spray or mist. Whenever mixing with water is required, always make sure that the acid is added to water and not the other way around.</p>,
    subdescriptions3:
      "The following safety measures should be taken when dealing with Sulphuric Acid:",
    
      listPassage:
      "The following safety measures should be taken when dealing with Sulphuric Acid:",
    list: [
      {
        id: 1,
        listData: "Concentrated solutions of acid are extremely corrosive.",
      },
      {
        id: 2,
        listData:
          "Dissolution of sulphuric acid in water is very exothermic; enough heat may be released to make the water boil.",
      },
      {
        id: 3,
        listData:
          "Always use safety glasses, neoprene, butyl rubber, polyethylene or PVC gloves, and protective clothing while handling sulphuric acid.",
      },
      {
        id: 4,
        listData:
          "If swallowed, do not induce vomiting. Drink plenty of water and seek medical help immediately.",
      },
      {
        id: 5,
        listData:
          "Contact with the eyes or skin can cause serious permanent damage. In case of contact with eyes or skin, wash the affected area with plenty of water for at least 15 minutes and seek medical help immediately.",
      },
    ],
    note: "Please note that the above mentioned safety measures are ‘guidelines’ only. If you should require further information, please do not hesitate to Contact us.",
    tableheading: "TECHNICAL SPECIFICATIONS OF ACI’S Sulphuric Acid",
    ProdList: [
      { name: "Chemical formula", score: <p>H<sub>2</sub>SO<sub>4</sub></p>},
      { name: "Colour", score: "Colourless to slightly yellow oily liquid" },
      { name: "Odour", score: "Odourless" },
      { name: "Molecular Mass", score: "98.06 g/mol" },
      { name: "Purity", score: "98 ±0.5%" },
      { name: "Density at 15.5 oC", score: "1.84 g/cm3" },
      { name: "Melting Point", score: "-35 oC to 10.37 oC (93% to 100% purity)" },
      { name: "Boiling Point", score: "290 oC to 338 oC (decomposes)" },
      { name: "Vapour pressure at 20 oC", score: "< 0.0001 kPa" },
      { name: "Iron, [Fe]", score: "< 50 ppm (part per million)" },
      { name: "Residue on ignition", score: "0.1 ±0.02" },
      { name: "Solubility", score: "Exothermically soluble in water" },
    ],
  },
];
