export const AboutData=[
    {
        id:1,
        title:"COMPANY PROFILE",
        detail:[
            {
                id:1,
                subtitle:'Manufacturer’s Profile',
                description : "Akbari Agrichem (Pvt) Ltd was established in 1979 with a vision to produce quality industrial chemicals and to give support to nation’s export potential. Since, its humble inception in 1979 with a Sulphuric Acid Plant, ACI has grown and expanded adding several important industrial chemicals to its products range over three decades. Today, the company’s product range in addition to Sulphuric Acid includes Formic Acid, Aluminium Sulphate, Sodium Sulphate, Single Super Phosphate (SSP), Acetec® and Sulphonic Acid (LABSA). All the products are manufactured on imported technology plants from China, Italy and United Kingdom. Establishment of a Linear Alkyl Benzene Sulphonic Acid (LABSA) or simply ‘Sulphonic acid’ (soft grade) production plant is the latest diversification project of ACI. Keeping in view the gap in supply and demand, ACI is already in process to expand its production facility in near future Insha’Allah."
    
            },
            // {
            //     id:2,
            //     subtitle:'Akbari Agrichem (Pvt) Ltd.',
            //     description : "(ACI), producers of sulphuric acid, sulphur rolls, fine sulphur powder, aluminium sulphate, formic acid, Acetec®,LABSA and Single Super Phosphate (SSP), is a sister concern of Akbari Stores (Private) Limited and an integral part of Akbari Group. ACI has come a long way since its humble inception in 1979 with a 10 ton-per-day sulphuric acid plant. Building on its experience and customer satisfaction for over three decades, ACI has grown, expanded, and diversified. We pray to almighty for His guidance and blessings in our efforts to continue to serve the nation. (InShaAllah)"
    
            // }
        ]
    },
    {
        id:2,
        title1:"COMPANY TEAM",
        title:"OUR COMPANY EXPERTS",
        cardData: [
            {
                id:1,
              title: "Mr. Monim Akbar",
              subtitle: "Chief Executive Officer",
            },
            {
                id:2,
              title: "Mr. Ataa Elahi",
              subtitle: "Company Secretary",
            },
            {
                id:3,
              title: "Mr. Wasee Ahmad",
              subtitle: "Director",
            },
            {
                id:4,
              title: "Mr. Zafar-Ul-Amin",
              subtitle: "Director",
            },
            {
                id:5,
              title: "Mr. Sadiq Ali",
              subtitle: "Director",
            },
        ]
    },
    {
        id:3,
        title:"ENVIRONMENT POLICY",
        detail:[
            {
                id:1,
                // subtitle:'POLICY    ',
                description : "Akbari Agrichem (Pvt) Ltd. is fully committed to protecting the environment and maintaining healthy and safety workplace for all its employees. The Managing Director’s Statement of Commitment in Environmental, Health and Safety has been widely distributed the Organization and is prominently displayed in the Environmental Health & Safety system. This statement is the foundation for the specific policies presented in the EHS System and for the supporting plans, guild lines & work practices. We have created EHS System that ensures long term success and sustainability in meeting and surpassing its Environmental, Health and Safety Obligations. Each component of the EHS System is designed to work interdependently in an integrated fashion that continually reinforces the common objective of improving EHS performance. To ensure the organizational sustainability of the EHS System, all components of the Management System such as Policy, Training, Planning, Audits, and Inspections etc have been designed and established as formal systems, integrated into on-going operations and monitoring on a regular basis to identify areas for continual improvement. It is these core systematic components that act both independently and cooperatively to form the backbone of the EHS System."
    
            }
        ]
    },
    {
        id:4,
        title: "CERTIFICATION",
    }
]