export const CompanyGroupData = [
    {
        id:1,
        title:"AKBARI STORE (PVT.) LTD.",
        subTitle: "AKBARI STORE (PVT.) LTD.",
        passageFirst:"Akbari Stores (Private) Limited was established by Sheikh Siraj-ud-Din Ahmed in 1948, later headed by his son Sheikh Fazal Elahi Akbar and since then it has become a trusted name for quality chemicals in Pakistan. With a product range of over 200 chemicals, Akbari Chemical Store is a leader in chemical supplies and provides all kinds of chemicals and detergents ranging from household to industrial use.",
        passageTwo: "Akbari Chemical Store was the foundation on which Akbari Group was later established. It is a family owned business with several group companies and is currently headed by Sheikh Wasee Ahmed, son of Sheikh Fazal Elahi Akbar. Our vision and goal is to continue with our efforts of serving the nation based on honesty, hard work and customer satisfaction."
    },
    {
        id:2,
        title:"Akbari Paper Store Lahore",
        subTitle: "AKBARI PAPER STORE",
        passageFirst:"Akbari Paper Store was established in 1990 by Sheikh Muhammad Ahmed Raza. It is an integral part of Akbari Group. Its establishment brought diversification to the business and within a few years it made Akbari Group a trusted name for imported paper. With over 20 years of imported paper trading, Akbari Paper Store has developed a commendable repute amongst its clientele for providing a variety of quality imported Paper products and continues to strive for the best possible customer service in future. (InShaAllah)",
        // passageTwo: "Akbari Chemical Store was the foundation on which Akbari Group was later established. It is a family owned business with several group companies and is currently headed by Sheikh Wasee Ahmed, son of Sheikh Fazal Elahi Akbar. Our vision and goal is to continue with our efforts of serving the nation based on honesty, hard work and customer satisfaction."
    },
    {
        id:3,
        title:"Akbari International Corporation",
        subTitle: "Akbari International Corporation",
        passageFirst:"Akbari International Corporation also known as AIC is a leading international trading and indenting company serving various Chemical and Paper industries both locally and internationally. AIC is a subsidiary of Akbari Group (Est 1948) and is undoubtedly the most reliable source for textile, leather, pharmaceutical, foaming, food and beverage chemicals along with paper products. Our job is not just to connect our valued customers to the right suppliers but also to provide them with the service that develops trust and a long lasting relationship.",
        passageTwo: "We began as a small chemical indenting house a couple of decades ago and since then we have expanded and diversified through our immense hard work and devotion to our clients. At AIC customer satisfaction is our passion and we take great pride in doing this justice to our valued customers and strive harder every day to maintain our ‘goodwill’."
    }
]