import React from "react";
import productimg1 from "../Asset/bag.jpg";
// import productimg2 from "../Asset/349product_290.jpg";
import productimg3 from "../Asset/46product_462.jpg";
import productimg2 from "../Asset/349product_290.jpeg";
import { Link } from "react-router-dom";

function ProductCard() {
  const productCards = [
    {
      id: 1,
      title: "HYDROCHLORIC ACID (HCl)",
      discription:
        "Hydrochloric Acid also known as muriatic acid is an aqueous solution of hydrogen chloride with chemical formula HCl. It is a colorless solution with a distinctive pungent smell. Without the HCl being dissolved in water, HCl is naturally a poisonous gas at room temperature",
      img: productimg3,
      Linlroute:"/Productpage/1"
    },
    {
      id: 2,
      title: "SULPHATE OF POTASH (SOP)",
      discription: <p>Sulphate of potash (SOP) also called Potassium Sulphate, is an inorganic compound with formula K<sub>2</sub>SO<sub>4</sub>. It is a white water-soluble powder. It is commonly used in fertilizers, providing both potassium and sulfur.</p>,
      img: productimg2,
      Linlroute:"/Productpage/2"
    },
    {
      id: 3,
      title: "SULPHURIC ACID",
      discription:
        "Sulphuric Acid is a strong dibasic acid. In addition, it is also a strong oxidizing and dehydrating agent. It is one of the most widely employed chemicals and enters into many industries, though infrequently appearing in the finished material.",
      img: productimg3,
      Linlroute:"/Productpage/4"
    },
  ];

  return (
    <>
      {productCards &&
        productCards.map((items) => (
          <div className="col-md-4  m-2" key={items.id} >
            <figure>
              <div className="product-container">
                <img src={items.img} alt="" className=" d-block product-img" />

                <div className="content">
                  <h3>{items.title}</h3>
                </div>
              </div>
            </figure>

            <b>{items.title}</b>
            <p className="product-textparagragh">{items.discription}</p>
           <Link to={items.Linlroute}> <button className="btn contact-btn">Read more</button></Link>
          </div>
        ))}
    </>
  );
}

export default ProductCard;
