import React from "react";
import classNames from "classnames";

function Cards({ title, Subtitle, ...rest }) {
  const classes = classNames(rest.className);

  return (
    <>
        <div className="card" style={{ width: "18rem" }}>
      <div className={classes}>
          <div className="card-body text-center">
            <h5 className="card-title">{title}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{Subtitle}</h6>
            {/* <p className="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
